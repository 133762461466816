import React from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const TextBlock = (props) => {
  const options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => <h2 className="headline">{children}</h2>,
      [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>
    },
    renderText: text =>
      text.split("\n").flatMap((text, i) => [i > 0 && <br key={i} />, text])
  }

  return (
  	<div className={`text-block ${props.theme}`}>
      { documentToReactComponents(props.copy.json, options) }
  	</div>
  )
}

export default TextBlock;