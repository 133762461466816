import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Banner from './Banner';
import AppContext from '../contexts/AppContext';

const Partners = ({content, partnerLogos}) => {
	const contentItems = content.description.json.content;
	const { globalLoc } = React.useContext(AppContext);

	return (
		<div className="partners content__item">
			<Banner text={globalLoc === 'syd' ? 'Recent Partners ↓' : 'Our Proud Partners ↓' } />
			<div className="partners__content">
				{documentToReactComponents(contentItems[0])}
				<div className="partners__logos">
					{ partnerLogos.map((l,i) => <img key={i} src={l.url + '?fm=png'} alt="partner logo" />) }
				</div>
			</div>
		</div>
	)
}

export default Partners;