import React, { useEffect, useRef }  from 'react';
import { useHistory } from "react-router-dom";
import AppContext from '../contexts/AppContext';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import arrowImg from '../images/arrow.svg';
import LazyLoad from 'react-lazyload';

const ListItem = (props) => {
	const appContext = React.useContext(AppContext);
	const { globalLoc } = appContext;
	const hasExpanded = props.content.expanded || props.content.slug.includes('/');
	let history = useHistory();
	const contentItemEl = useRef(null);
	const copyEl = useRef(null);

	const options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => <h2 className="headline">{children}</h2>,
      [BLOCKS.PARAGRAPH]: (node, children) => <p>
      	{children}
      </p>
    },
    renderText: text =>
      text.split("\n").flatMap((text, i) => [i > 0 && <br key={i} />, text])
  }

  const expandContent = (e) => {
  	appContext.setHomeTop(contentItemEl.current.offsetTop);
  	contentItemEl.current.classList.add('active');
  	setTimeout(() => {
  		if(props.content.slug.includes('/')){
  			history.push(props.content.slug)
  		} else {
  			history.push(`/work/${props.content.slug}`);
  		}
  		window.scrollTo(0, 0);
  	}, 500)
  }

	const displayMedia = (media) => {
		if(media){
			const type = media.contentType.includes('image') ? 'image' : 'video';
			switch(type){
				case 'image':
        return (
        	<div className="embedded__media">
        		<div className="embedded__media__description">
        			{ appContext.currentPage === 'work' || appContext.currentPage === 'culture' ? <p className="client">{props.content.client}</p> : null }
        			<p>{props.content.title}</p>
        		</div>
						<LazyLoad height="100%">
    					<img src={media.url + '?fm=jpg&q=80'} alt={media.title} />
						</LazyLoad>
    			</div>
        )
        break;
      case 'video':
        return (
        	<div className="embedded__media">
        		<div className="embedded__media__description">
        			{ appContext.currentPage === 'work' || appContext.currentPage === 'culture' ? <p className="client">{props.content.client}</p> : null }
        			<p>{props.content.title}</p>
        		</div>
    				<video src={media.url + '?q=75'} muted autoPlay loop playsInline></video>
    			</div>
        )
        break;
			}
		}
	}

	return (
		<div className={`content__item ${ props.content.theme ? props.content.theme : ''} ${appContext.currentPage === 'work' ? 'button' : ''}`} ref={contentItemEl} onClick={appContext.currentPage === 'work' ? expandContent : null}>
			<div className="content__item__section description">
				{ props.content.description ? (
				<div className={`section__copy ${ hasExpanded ? 'fade' : ''}`} ref={copyEl}>
					{
						documentToReactComponents(props.content.description.json, options)
					}
					{ hasExpanded && appContext.currentPage !== 'work' ? (
					<div className="expand-icon" onClick={expandContent}><img src={arrowImg} /></div>
					) : null
					}
				</div>
				) : null
				}
				<div className="section__background">
					{ displayMedia(props.content.media) }
				</div>
			</div>
		</div>
	)
}

export default ListItem;