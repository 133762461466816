import React from 'react';
import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import AppContext from '../contexts/AppContext';
import logo from '../images/72_logo.svg';
import { useHistory } from "react-router-dom";

const Nav = (props) => {
	const appContext = React.useContext(AppContext);
	const { globalLoc } = appContext;
	const [scrollDirection, setScrollDirection] = useState(null);
	const [pages, setPages] = useState([]);
	const navLogo = useRef(null);
	let lastScrollTop = 0;
	const rootUrl = window.location.origin;
	const history = useHistory();

  const toggleMenu = () => {
    return props.setNavExpanded(!props.navExpanded ? true : false)
  }

  const scrollHandler = (e) => {
	  var st = window.pageYOffset || document.documentElement.scrollTop;

	   if (st > lastScrollTop){
	      setScrollDirection('down');
	   } else {
	      setScrollDirection('up');
	   }
	   lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
  }

  useEffect(()=> {
  	if(appContext.scrollY > 200 && scrollDirection !== 'up'){
  		navLogo.current.classList.add('hidden');
  	} else {
  		navLogo.current.classList.remove('hidden');
  	}
  }, [appContext.scrollY, appContext.homeTop]);

  useEffect(() => {
  	if(appContext.pageCategory === 'work' && appContext.currentPage !== 'work'){
      appContext.setOrangeNav(true);
    } else {
      appContext.setOrangeNav(false);
    }
  },[appContext.pageCategory, appContext.currentPage])

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);
    window.addEventListener('wheel', scrollHandler);
    window.addEventListener('touchmove', scrollHandler);
    return () => {
      window.removeEventListener('scroll', scrollHandler);
      window.removeEventListener('wheel', scrollHandler);
      window.removeEventListener('touchmove', scrollHandler);
    };
  }, []);

  // useEffect(() => {
  // 	const PAGES_QUERY = `query {
  // 		pageCollection {
	// 	    items {
	// 	      title
	// 	      slug
	// 	      navLabel
	// 	    }
	// 	  }
  // 	}`;

  // 	axios({
  //     url: `https://graphql.contentful.com/content/v1/spaces/${process.env.REACT_APP_SPACE}/environments/${appContext.env}`,
  //     method: "GET",
  //     headers: {
  //       "Authorization": `Bearer ${appContext.cDelivery}`,
  //       "Content-Type": "application/json"
  //     },
  //     params: {
  //       query: PAGES_QUERY
  //     }
  //   })
  //   .then(({ data }) => {
  //     setPages(data.data.pageCollection.items);
  //   })
  //   .catch(console.error)
  // }, [])

  const goToPage = (page) => {
  	toggleMenu();
  	appContext.setHomeActive(false);
  	setTimeout(() => {
  		history.push(`/${page}${ globalLoc !== 'us' ? `/${globalLoc}` : '' }`);
  	}, 100);
  }

  return (
    <div className={`nav ${appContext.orangeNav ? 'orange' : ''}`}>
			<div className="nav__logo" ref={navLogo}>
				<a href="/" rel="noreferrer"><img src={logo} alt="72andSunny" /></a>
			</div>
			<div className="nav__menu">
				<div className={props.navExpanded ? 'nav__menu__button close' : 'nav__menu__button'} onClick={toggleMenu}>
					<div className="line"></div>
					<div className="line"></div>
				</div>

				<div className={props.navExpanded ? 'nav__menu__expanded active' : 'nav__menu__expanded'}>
					<ul className="nav__menu__expanded__list">
					{/* dynamic menu */}
						{/*{
							pages?.map((p,i) => {
								return p.navLabel ? (
									<li className="list__item" key={i}><a href={`${rootUrl}/${p.slug}`} rel="noreferrer">{p.navLabel}</a></li>
								) : null
							})
						}*/}
						<li className="list__item" onClick={() => goToPage('about')}>Who We Are</li>
						<li className="list__item" onClick={() => goToPage('work')}>Work We Make</li>
						<li className="list__item" onClick={() => goToPage('culture')}>Our Culture</li>
						<li className="list__item" onClick={() => goToPage('news')}>New News</li>
						<li className="list__item" onClick={() => goToPage('strategy-studio')}><a href={`${rootUrl}/strategy-studio`} rel="noreferrer">Strategy Studio</a></li>
						<li className="list__item" onClick={() => goToPage('contact')}><a href={`${rootUrl}/contact`} rel="noreferrer">Let&rsquo;s Chat</a></li>
		    	</ul>
		    	<ul className="nav__menu__expanded__list social">
		    		<li className="list__item"><a href="https://instagram.com/72andsunny_" target="_blank" rel="noreferrer">Instagram</a></li>
		    		<li className="list__item"><a href="https://www.linkedin.com/company/72andsunny?original_referer=" target="_blank" rel="noreferrer">LinkedIn</a></li>
						<li className="list__item"><a href="https://medium.com/@72andSunny" target="_blank" rel="noreferrer">Blog</a></li>
		    	</ul>
					<ul className="nav__menu__expanded__list global">
						<li className="list__item"><a href={`${rootUrl}/global`}  rel="noreferrer">Los Angeles</a></li>
						<li className="list__item"><a href={`${rootUrl}/global`}  rel="noreferrer">New York</a></li>
						<li className="list__item">Amsterdam</li>
						<li className="list__item"><a href={`${rootUrl}/syd`}  rel="noreferrer">Sydney</a></li>
					</ul>
				</div>
			</div>
		</div>
  )
}

export default Nav;