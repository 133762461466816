import React from 'react';
import { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
} from "react-router-dom";
import { getCountry } from './helpers/LocationDetection';
import { AppProvider } from './contexts/AppContext';
import { PageProvider } from './contexts/PageContext';
import { WorkProvider } from './contexts/WorkContext';
import Nav from './components/Nav';
import FullPage from './components/FullPage';
import Page from './components/Page';
import About from './pages/About';
import InstagramFeed from './components/InstagramFeed';
import TermsAndConditions from './pages/TermsAndConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';

const App = () => {
  const cPreview = process.env.REACT_APP_PREVIEW;
  const cDelivery = process.env.REACT_APP_DELIVERY;
  // const env = window.location.href.includes('staging') || window.location.href.includes('localhost') ? 'staging' : 'production';
  const env = process.env.REACT_APP_ENV;
  const [navExpanded, setNavExpanded] = useState(false);
  const [homeActive, setHomeActive] = useState(false);
  const [currentPage, setCurrentPage] = useState('');
  const [homeTop, setHomeTop] = useState(0);
  const [homeEl, setHomeEl] = useState(null);
  const [scrollY, setScrollY] = useState(0);
  const pageCategory = window.location.pathname.split('/')[1];
  const urlSlug = window.location.pathname.split('/')[2];
  const [orangeNav, setOrangeNav] = useState(false);
  const [globalLoc] = useState(() => {
    const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const country = getCountry();
    // if (urlSlug === 'ams' || urlSlug === 'syd') {
    //   return urlSlug;
    // }
    // if (pageCategory === 'ams' || pageCategory === 'syd') {
    //   return pageCategory;
    // }
    if (urlSlug === 'syd') {
      return urlSlug;
    }
    if (pageCategory === 'syd') {
      return pageCategory;
    }
    // if (currentTimeZone.includes('Europe')) {
    //   return 'ams';
    // }
    if (country === 'Australia' || country === 'New Zealand') {
      return 'syd';
    }
    return 'us';
  });
  const globalPages = {
    "master": {
      "us": {
        "home":"2UQvbPwcMbWIdOQuLTpU4H",
        "news":"6KyJGAb2KbGMrC4uGCrMRF",
        "culture":"5xCwF06YvXsFak1Ds04W1s",
        "work":"6gHi2rROql8YIyeOijQb0B"
      },
      "ams": {
        "home":"2UKcqtEF3ZbvUgnWIZJmFD",
        "news":"1rcELLK3JwfpHAyWkQHDwi",
        "culture":"1ThmZBTtBz26aZ6B6CEKGD",
        "work":"6DMhvv2gsJVyKdXh0IsFMp"
      },
      "syd": {
        "home":"7tM0wltUZ6bQDXxCb9G9Wh",
        "news":"7jIvRrez0xCE0I57GUUiZW",
        "culture":"4C6H8z2pLn906VV6SiREpY",
        "work":"6B5TTXzT6GBn3MoKWnVGWb"
      }
    },
    "staging": {
      "us": {
        "home":"2UQvbPwcMbWIdOQuLTpU4H",
        "news":"6KyJGAb2KbGMrC4uGCrMRF",
        "culture":"5xCwF06YvXsFak1Ds04W1s",
        "work":"6gHi2rROql8YIyeOijQb0B"
      },
      "ams": {
        "home":"7y9BWpl1e6js4RlaViNuh0",
        "news":"5yVJThVgVAVtcfivoZ6Pm3",
        "culture":"6Iz6TFN8y5TNm3ZtqkL6CD",
        "work":"5ISwCTSTiu6vUgR1KykCDc"
      },
      "syd": {
        "home":"6zHJi9LGULjcfu90gslmw",
        "news":"3lkQHvNM5Jwnhvje4yfl9s",
        "culture":"01AhXWm4L3jsWWErflRabl",
        "work":"1V327LTXEZZAeycq5LggB9"
      }
    },
    "production": {
      "us": {
        "home":"2UQvbPwcMbWIdOQuLTpU4H",
        "news":"6KyJGAb2KbGMrC4uGCrMRF",
        "culture":"5xCwF06YvXsFak1Ds04W1s",
        "work":"6gHi2rROql8YIyeOijQb0B"
      },
      "ams": {
        "home":"2StSbSsyGUG42hR9n9TWW5",
        "news":"1FPU3IS4S92q5mFA0aDk2r",
        "culture":"5HDM97UGxWZFCvsjLLMjmk",
        "work":"3rZADek2bY10oCKDmFY4Xu"
      },
      "syd": {
        "home":"7KSpQPxvOY6AEJYpcrcB8a",
        "news":"4fKV0trE650pAdbqcPZCzS",
        "culture":"4bg2XCmcIhshg5Hoa6eka1",
        "work":"2x8r4DS4aSwLSte9EznBl"
      }
    }
  }

  const scrollHandler = () => {
    setScrollY(window.scrollY);
  }

  useEffect(() => {
    // scroll to top of page
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);
    window.addEventListener('wheel', scrollHandler);
    window.addEventListener('touchmove', scrollHandler);
    return () => {
      window.removeEventListener('scroll', scrollHandler);
      window.removeEventListener('wheel', scrollHandler);
      window.removeEventListener('touchmove', scrollHandler);
    };
  }, [homeEl, currentPage]);

  return (
    <div className={`app ${ navExpanded ? 'nav-expanded' : ''} ${currentPage === 'home' ? 'home' : ''}`}>
      <AppProvider value={{cPreview: cPreview, cDelivery: cDelivery, env: env, setScrollY: setScrollY, setHomeActive: setHomeActive, homeActive: homeActive, homeTop: homeTop, setHomeTop: setHomeTop, scrollY: scrollY, currentPage: currentPage, pageCategory: pageCategory, setCurrentPage: setCurrentPage, setHomeEl: setHomeEl, homeEl: homeEl, orangeNav: orangeNav, setOrangeNav: setOrangeNav, globalLoc: globalLoc}}>
      <Router>
        <Nav navExpanded={navExpanded} setNavExpanded={setNavExpanded} />
        <Route exact path={["/", "/ams", "/syd"]}>
          <Redirect to={`/home${globalLoc === 'ams' || globalLoc === 'syd' ? `/${globalLoc}` : ''}`} />
        </Route>
        <Route exact path="/global">
          <Redirect to={`/home/us`} />
        </Route>
        <Route exact path="/home/:office?">
          <PageProvider pageId={globalPages[env][globalLoc].home}>
            <Page pageName="home" />
          </PageProvider>
        </Route>
        <Route exact path="/about/:office?">
          <PageProvider pageId="5vFm4QHUzNjW6IhO8BIXt9">
            <About pageName="about" />
          </PageProvider>
        </Route>
        <Route exact path="/culture/:office?">
          <PageProvider pageId={globalPages[env][globalLoc].culture}>
            <Page pageName="culture" />
          </PageProvider>
        </Route>
        <Route exact path="/news/:office?">
          <PageProvider pageId={globalPages[env][globalLoc].news}>
            <Page pageName="news" />
          </PageProvider>
        </Route>
        <Route exact path="/strategy-studio">
          <PageProvider pageId="5TFmb4wZtP60ghaGl3YK3i">
            <Page />
          </PageProvider>
        </Route>
        <Route exact path="/contact/:office?">
          <PageProvider pageId="5NKZO3G0FF3fQhhAjxvxdK">
            <Page pageName="contact" />
          </PageProvider>
        </Route>
        <Route exact path="/instagram">
          <InstagramFeed />
        </Route>
        <Route exact path="/work/:slug?">
        {

          urlSlug === undefined || urlSlug === '' || urlSlug === 'us' && pageCategory === 'work' && globalLoc === 'us' || urlSlug === 'ams' || urlSlug === 'syd' ? (
            <PageProvider pageId={globalPages[env][globalLoc].work}>
              <Page pageName="work" />
            </PageProvider>
          ) : (
            <WorkProvider pageId={globalPages[env][globalLoc].work}>
            <PageProvider>
              <FullPage pageName="work" />
            </PageProvider>
            </WorkProvider>
          )
        }
        </Route>
        <Route exact path="/terms-and-conditions">
          <TermsAndConditions />
        </Route>
        <Route exact path="/privacy-policy">
          <PrivacyPolicy />
        </Route>
      </Router>
      </AppProvider>
    </div>
  )
}

export default App;