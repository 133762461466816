import React, { useEffect, useRef, useState } from 'react';

const Footer = () => {
	const footerEl = useRef(null);
	const timerId = useRef(null);
	const [currentYear] = useState(new Date().getFullYear());

useEffect(() => {
   timerId.current = setTimeout(() => {
      footerEl.current.classList.add('loaded')
   }, 500);
}, []);

	return (
		<div className="footer" ref={footerEl}>
			<div className="footer__column left">
				<div className="footer__email"><p>&copy; 72andSunny {currentYear}</p></div>
				<div className="terms-privacy">
					<a href="/terms-and-conditions" target="_blank" rel="noreferrer">Terms and Conditions</a>
				</div>
				<div className="terms-privacy">
					<a href="/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a>
				</div>
			</div>
			<div className="footer__column right social">
				<div className="social__link"><a href="https://instagram.com/72andsunny_" target="_blank" rel="noreferrer">Instagram</a></div>
				<div className="social__link"><a href="https://www.linkedin.com/company/72andsunny" target="_blank" rel="noreferrer">LinkedIn</a></div>
				<div className="social__link"><a href="https://medium.com/@72andSunny" target="_blank" rel="noreferrer">Blog</a></div>
			</div>
		</div>
	)
}

export default Footer;