import React, { useEffect, useRef } from 'react';

const Banner = ({text}) => {
	const bannerEl = useRef(null);
	const bannerText = useRef(null);

	useEffect(() => {
		const textWidth = bannerText.current.offsetWidth;

		bannerEl.current.innerHTML = `<span>${text}</span><span>${text}</span><span>${text}</span><span>${text}</span><span>${text}</span><span>${text}</span>`;
		bannerEl.current.style.setProperty('--textIndent', `-${textWidth + 10}px`)
	}, [])

	return (
		<div className="banner" ref={bannerEl}>
			<span ref={bannerText}>{text}</span>
		</div>
	)
}

export default Banner;