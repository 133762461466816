import React from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { useHistory } from "react-router-dom";
import AppContext from '../contexts/AppContext';
import arrowImg from '../images/arrow.svg';
import Flickity from 'react-flickity-component';
import 'flickity/dist/flickity.min.css';

const CustomSlideshow = ({content, images}) => {
  const appContext = React.useContext(AppContext);
  const history = useHistory();
  const options = {
    prevNextButtons: false,
    pauseAutoPlayOnHover: false,
    pageDots: false,
    autoPlay: 8000,
    wrapAround: true,
    draggable: false
  }

  return (
  	<div className={`content__item custom-slideshow`}>
      <div className="custom-slideshow__content">
        <h2 className="headline" onClick={ () => appContext.currentPage === 'home' ? history.push(content.slug) : null }>
          {content.headline}
          {
            appContext.currentPage === 'home' ? (
              <div className="expand-icon" onClick={ () => history.push(content.slug) }><img src={arrowImg} /></div>
            ) : null
          }
        </h2>
        {
          appContext.currentPage !== 'home' ? (
            <span>{content.mainCopy}</span>
          ) : null
        }
      </div>
      <Flickity elementType={'div'} options={options}>
        { images.map((s,i) => <div className="slider__item" key={i}><img  src={s.url} /> </div> ) }
      </Flickity>
  	</div>
  )
}

export default CustomSlideshow;