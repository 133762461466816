import React from 'react';
import AppContext from '../contexts/AppContext';
import { PageContext } from '../contexts/PageContext';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import LazyLoad from 'react-lazyload';

const ContentItem = (props) => {
	const appContext = React.useContext(AppContext);
  const pageContext = React.useContext(PageContext);
	const options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => <h2 className="headline" style={{color: `${props.content.media ? '#FFF' : ''}`}}>{children}</h2>,
      [BLOCKS.PARAGRAPH]: (node, children) =>
       <p style={{color: `${props.content.media ? '#FFF' : 'inherit'}`}}>
       {
        children.map((c, i) => {
          if(c.type === 'a'){
            return <a key={i} href={c.props.href} target="_blank">{c.props.children[0]}</a>
          } else {
            return children[i]
          }
        })
       }</p>,
      [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {

        const entryObj = pageContext.embeddedEntries.find(o => o.id === node.data.target.sys.id);

        return (
          entryObj ? (
            <div className={`embed__item ${entryObj.theme ? entryObj.theme : ''} ${entryObj.slug}`}>
                <div className="embed__item__section description">
                  <div className="section__copy">
                    {
                      pageContext.content.length > 0 && pageContext.content[0].client ? (
                        <h3>{pageContext.content[0].client}</h3>
                      ) : null
                    }
                    {
                      entryObj.description ? (
                        documentToReactComponents(entryObj.description.json, options)
                      ) : null
                    }
                  </div>
                </div>
                {
                  entryObj.expanded ? (
                    <div className="embed__item__section expanded">
	                    { documentToReactComponents(entryObj.expanded.json, options) }
	                 </div>
                  ) : null
                }
                {
                  entryObj.media ? (
                    <div className="section__background">
                      { displayMedia(entryObj.media) }
                    </div>
                  ) : null
                }
              </div>
          ) : null
        )
      },
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const assetObj = pageContext.embeddedAssets.find(o => o.id === node.data.target.sys.id);

        return (
          assetObj && assetObj.contentType.includes('image') ? (
            <LazyLoad height="100%"><img src={assetObj.url + '?fm=jpg&q=80'} /></LazyLoad>
          ) : assetObj && assetObj.contentType.includes('video') ? (
            <LazyLoad height="100%"><video src={assetObj.url + '?q=75'}></video></LazyLoad>
          ) : null
        )
      }
    }
  }

  const displayMedia = (media) => {
    if (media) {
      const type = media.contentType.includes('image') ? 'image' : 'video';
      let url;

      switch (type) {
        case 'image':
          return (
            <div className="embedded__media">
              <div className="embedded__media__description">
                <p className="caption">{media.description}</p>
              </div>
              {
                appContext.currentPage === 'news' ? (
                  props.content.description.json.content.map(c => {
                    url = c.content.filter(l => l.nodeType === 'hyperlink')
                  }),
                  <a className="image-link" href={url.length > 0 ? url[0].data.uri : null} target="_blank"><img src={media.url + '?fm=jpg&q=80'} alt={media.title} /></a>
                ) : (
                  <img src={media.url + '?fm=jpg&q=80'} alt={media.title} />
                )
              }
            </div>
          )
          break;
        case 'video':
          return (
            <div className="embedded__media">
              <div className="embedded__media__description">
                <p className="caption">{media.description}</p>
              </div>
              <video src={media.url + '?q=75'} muted autoPlay loop></video>
            </div>
          )
          break;
      }
    }
  }

	return(
    <div className={`content__item ${props.content.slug} ${props.content.theme ?? ''}`}>
      <div className="content__item__section top"  style={{minHeight: `${props.content.media ? '' : 'unset'}`}}>
        <div className="copy" style={{color: `${props.content.media ? '#FFF' : ''}`}}>
          { props.content.description ? documentToReactComponents(props.content.description.json, options) : null }
        </div>
        {
          props.content.media ? (
            <div className="section__background">
            { displayMedia(props.content.media) }</div>
          ) : null
        }
      </div>
      { props.content.expanded ? (
        <div className={`content__item__section expanded`}>
         { props.content.expanded ? documentToReactComponents(props.content.expanded.json, options) : null }
        </div>
      ) : null }
    </div>
	)
}

export default ContentItem;