import React from 'react';
import { useEffect } from 'react';
import AppContext from '../contexts/AppContext';
import Footer from '../components/Footer';

const TermsAndConditions = () => {
	const appContext = React.useContext(AppContext);

	useEffect(() => {
		appContext.setOrangeNav(true);
	}, []);

  return (
    <div className="page terms-privacy">
				<h1>Terms and Conditions of Use</h1>
    <p>Welcome to www.72andsunny.com (“Website”), a website provided by 72andSunny Partners LLC (“We”). Your (“You”) use of the Website is at all times subject to the following terms and conditions (“Terms”) and you are deemed to have accepted them (the “Agreement”). We encourage you to carefully read these Terms and to make sure you understand them. In the event that you do not agree to these Terms, please immediately exit the Website.</p>
    <h2>1. Your use of the Website</h2>
    <p>We provide the Website for promotional, informational and entertainment purposes only. You may not rely on any information or opinions expressed on the Website for any other purpose. You agree that you will use the Website solely for your own, non-commercial, personal use in accordance with these Terms. For clarity, the Website may not be used for any unlawful, fraudulent, harassing, objectionable or other non legitimate purposes.</p>

    <h2>2. The Website&rsquo;s content</h2>
    <p>The Website and all content on the Website including the design, graphics, text, sounds, pictures, images, software, the 72andSunny logo, name, slogans, designs and any and all other materials and information on the Website (collectively "Content"), and all copyrights, trademarks, service marks and other intellectual property rights, whether registered or not, in the Website and such Content, are owned or licensed by us. You may access such Content only as expressly permitted herein. No provision of the Terms and nothing contained on the Website, grants You, whether expressly, implicitly or otherwise, any license or other right to copy, disclose, distribute, retransmit, use or create derivative works of any Content without our written permission and any such exploitation such as copying, disclosure, distribution, retransmission, use, creation of derivative works is strictly prohibited.</p>

    <h2>3. Information You are providing us.</h2>
    <p>If You elect to submit any information and/or content through the Website (if such feature is offered) or through the email addresses listed on the Website, You represent that any such information are accurate and complete in all respects, unless by its nature such information is not intended to be accurate or complete and that You are the sole owner of such information or content or have a written permission from the rightful owner to share such information or content with us through the Website or by submitting such information or content through the email addresses provided on the Website. Subject to the terms of our <a href="/privacy-policy">Privacy Policy</a>, you grant to us an irrevocable, perpetual, worldwide, and fully paid license to copy, distribute, modify and create derivative works of all information or other content provided by You to us through the Website.</p>
		<h2>4. Your representations and warranties and indemnification obligations</h2>
<h3>4.1 You represent, warrant, and agree that you will not:</h3>
<ul>
    <li>use the Website in any unlawful manner, for any unlawful purpose, or in any manner inconsistent with this Agreement and/or any and all applicable local, state, national and international laws and regulations and treaties;</li>
    <li>permit or otherwise enable unauthorized users to access and/or use the Website;</li>
    <li>sell, copy, duplicate, rent, lease, loan, distribute, transfer, the Website or the Content, or otherwise permit any third party to use or have access to the Website or the Content for any purpose (except as expressly permitted by us in writing) or decompile, export or collect data in any manner including in any automated manner through the use of bots, metaspiders, crawlers or any other automated means, reverse engineer, disassemble, modify, create a derivative work of, display in human readable form, attempt to discover any source code, or otherwise use any software that enables or comprises any part of the Website;</li>
    <li>remove any copyright, trademark, patent or other proprietary notices from the Website or any Content;</li>
    <li>use the Website and/or the emails addresses provided on the Website by providing false, inaccurate, or misleading information;</li>
    <li>attempt to gain unauthorized access to our computer systems (including any non-public areas of the Service) or engage in any activity that disrupts, diminishes the quality of, probes for vulnerability, interferes with the performance of, or impairs the functionality of, the Website (or the servers and networks which are connected to the Website).</li>
</ul>

<h3>4.2 Indemnification</h3>
<p>You agree to indemnify and hold harmless us, our parents, subsidiaries, affiliates, directors, members, officers, employees, agents and third party licensors, third-party publishers, necessary third-party platform providers, from and against any and all liabilities, losses, damages, claims and expenses, including, without limitation, attorneys&rsquo; fees, with respect to (i) your access, use or misuse of, or reliance upon, the Website, (ii) your actual or alleged violation or breach of this Agreement or rights of another, (iii) content provided by you, including through the email addresses listed in the Website and/or (iv) your acts or omissions. You agree to cooperate fully with us in the defense of any claim that is the subject of your obligations hereunder. For the avoidance of doubt, this section shall survive the termination of this Agreement.</p>

<h2>5. DMCA Take-Down Procedure</h2>
<p>The Digital Millennium Copyright Act of 1998 (the “DMCA”) provides recourse for copyright owners who believe that material appearing on the Internet infringes their rights under U.S. copyright law. If You believe that any materials on this Website have been copied in violation of U.S. copyright law or otherwise used is used in a manner that constitutes copyright infringement , please provide us at the contact information below with the following information: (1) an electronic or physical signature of the copyright owner or of the person authorized to act on behalf of the owner of the copyright interest; (2) a description of the copyrighted work that You claim has been infringed; (3) a description of where the material that You claim is infringing is located on the Website; (4) your address, telephone number, and e-mail address; (5) a written statement by You that You have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law; and (6) a statement by You, made under penalty of perjury, that the above information in your notice is accurate and that You are the copyright owner or authorized to act on the copyright owner&rsquo;s behalf. Any notification by a copyright owner or a person authorized to act on its behalf that fails to comply with requirements of the DMCA shall not be considered sufficient notice and shall not be deemed to confer upon us actual knowledge of facts or circumstances from which infringing material or acts are evident.</p>
<p>Our contact information: By mail: 72andSunny Partners LLC - Legal department - 12101 Bluff Creek Dr, Playa Vista, CA 90094, and/or by email: legal@72andsunny.com</p>
<h2>6. Third parties websites</h2>
<p>The Website may contain links to other websites or services maintained by third parties. We do not operate or control, in any respect, such third parties websites and We don&rsquo;t necessarily endorse or otherwise approve the content (including but not limited to any opinions) found on those third-party websites or services. The links are provided for your convenience and You will assume complete responsibility for your use of those third-party websites, products and services. In addition, your use of such third parties websites will be governed by their terms of use and other provisions such as their privacy policies.</p>
<h2>7. Privacy Policy</h2>
<p>For information about our data protection practices, please read our Privacy Policy available <a href="/privacy-policy">here</a>. This policy explains how We treat your personal information, and how We protect your privacy when you use the Website. You agree to the use of your data in accordance with our Privacy Policy. The Privacy Policy is incorporated into these Terms.</p>
<h2>8. Warranties and liability</h2>
<h3>8.1 NO WARRANTIES.</h3>
<p>THE WEBSITE IS PROVIDED TO YOU ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT WARRANTY OR REPRESENTATION OF ANY KIND. TO THE FULLEST EXTENT PERMITTED BY LAW, WE EXPRESSLY DISCLAIM ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING WITHOUT LIMITATION, ANY WARRANTY OF MERCHANTABILITY, TITLE, NON-INFRINGEMENT, QUALITY, AND/OR FITNESS FOR A PARTICULAR PURPOSE. WITHOUT LIMITING THE FOREGOING, WE DO NOT WARRANT THE ACCURACY, RELIABILITY OR COMPLETENESS OF ANY INFORMATION PROVIDED BY US IN CONNECTION WITH YOUR USE OF THE WEBSITE, OR THAT THE WEBSITE, INCLUDING, WITHOUT LIMITATION, ANY DATA, FILES, AND/OR OTHER INFORMATION STORED ON A SERVER OWNED OR UNDER OUR CONTROL OR IN ANY WAY CONNECTED WITH THE WEBSITE, WILL MEET YOUR REQUIREMENTS OR BE AVAILABLE, UNINTERRUPTED, ERROR-FREE, VIRUS-FREE OR SECURE. WE TAKE NO RESPONSIBILITY AND ASSUME NO LIABILITY FOR ANY CONTENT THAT YOU OR ANY THIRD PARTY SUBMITS OR SENDS OVER THE WEBSITE/EMAIL ADDRESSES PROVIDED ON THE WEBSITE.</p>

<h3>8.2 DISCLAIMER OF LIABILITY</h3>
<p>TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT SHALL WE, OUR PARENTS, SUBSIDIARIES, AFFILIATES, OR ANY OF THEIR DIRECTORS, MEMBERS, MANAGERS, OFFICERS, EMPLOYEES, AGENTS OR THIRD PARTY LICENSORS, BE LIABLE FOR ANY SPECIAL, INDIRECT, INCIDENTAL, CONSEQUENTIAL, PUNITIVE OR EXEMPLARY DAMAGES OR LOSSES ARISING OUT OF OR RELATING TO THIS AGREEMENT AND/OR THE TERMS AND/OR THE WEBSITE PROVIDED HEREUNDER OR ANY OTHER INTERACTIONS WITH US, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THIS LIMITATION OF LIABILITY APPLIES WHETHER THE ALLEGED LIABILITY IS BASED ON CONTRACT, NEGLIGENCE, RECKLESSNESS, PROFESSIONAL NEGLIGENCE, TORT, STRICT LIABILITY OR ANY OTHER BASIS OR LEGAL THEORY.</p>

<h2>9. International use.</h2>
<p>Recognizing the global nature of the Internet, You agree to comply with all local rules regarding online conduct. Specifically, You agree to comply with all applicable laws regarding the transmission of technical data exported from the United States and/or the country in which you reside. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.</p>

<h2>10. Governing law and jurisdiction</h2>
<p>These Terms and our Agreement shall be governed by, and construed in accordance with, the laws of the United States and the State of California without giving effect to conflicts of law principles thereof. We make no representations that the material and information on this Website are appropriate or available in all national locations or languages. You agree that any action at law or in equity arising from or relating to the use of this Website or to these Terms shall be brought exclusively in the Federal or State Courts residing in California. You hereby consent and submit to personal jurisdiction in such courts for the purposes of any action relating to this Website, your access or use thereof, or these Terms, and to extraterritorial service of process.</p>

<h2>11. Entire Agreement and Severability</h2>
<h3>11.1 Entire agreement</h3>
<p>These Terms contain the entire agreement, and supersede all prior oral and written proposals, understandings and agreements, between You and us with respect to the Website and any Content.</p>

<h3>11.2 Severability</h3>
<p>If any provision in the Terms is invalid or unenforceable or contrary to applicable law, such provision shall be construed, limited, or altered, as necessary, to eliminate the invalidity or unenforceability or the conflict with applicable law, and all other provisions of this Agreement shall remain in effect.</p>

<h2>12. Revisions</h2>
<p>We may revise the Terms from time to time by posting the revised terms of service on the Website, with any revision taking effect as of the date of such posting. It is your responsibility to periodically check the Terms on the Website for such revisions. Your use of the Website following the posting of any revisions to the Terms on the Website will be deemed an irrevocable acceptance by You of such revisions.</p>

<p style={{marginTop: '20px', fontSize: '14px', fontStyle: 'italic'}}>These Terms and Conditions were last updated on April 2nd 2024</p>
				<Footer />
		</div>
  )
}

export default TermsAndConditions;