import React from 'react';
import AppContext from '../contexts/AppContext';
import { useState, useEffect } from 'react';
import Flickity from 'react-flickity-component';
import 'flickity/dist/flickity.min.css';

const mapOrder = (array, order, key) => {
  array.sort( function (a, b) {
    var A = a[key], B = b[key];
    
    if (order.indexOf(A) > order.indexOf(B)) {
      return 1;
    } else {
      return -1;
    }
    
  });
  return array;
};

const Carousel = (props) => {
  const appContext = React.useContext(AppContext);
  const [autoplay, setAutoplay] = useState(true);
	const [imagesLoaded, setImagesLoaded] = useState(false);
	const { itemExpanded } = appContext;
	const { items, carouselOrder, speed }	= props;

  let flkty = null;
  const o = [...carouselOrder].map(n => n.sys.id);
  const newOrder = mapOrder(items, o, 'id');

  const flickityOptions = {
    autoPlay: speed * 1000,
    pauseAutoPlayOnHover: false,
    wrapAround: true,
    prevNextButtons: false,
    setGallerySize: false
  }

  useEffect(() => {
  	return speed && (
  		flkty.on('ready', () => {
	  		return flkty.pageDots && (
	  			document.getElementsByClassName('flickity-page-dots')[0].style.display = 'block',
		  		flkty.pageDots.dots.map((d,i) => {
		  			d.style.width = `calc(100% / ${flkty.pageDots.dots.length} - 0.6vw)`
		  		})
	  		)
	  	})
  	)
  }, [speed]);

	useEffect(() => {
		const imageUrls = items
			.filter(slide => slide.contentType.includes('image'))
			.map(slide => slide.url);
	
		if (imageUrls.length === 0) {
			setImagesLoaded(true);
			return;
		}
	
		let isMounted = true; // to track component mount status
	
		const imageLoadPromises = imageUrls.map(url => {
			return new Promise((resolve, reject) => {
				const img = new Image();
				img.src = url;
				img.onload = resolve;
				img.onerror = () => {
					console.error("Failed to load image:", url);
					resolve(); // Resolve even on error to continue with other images
				};
			});
		});
	
		Promise.all(imageLoadPromises).then(() => {
			if (isMounted) {
				setImagesLoaded(true);
			}
		});
	
		return () => {
			isMounted = false; // Cleanup logic
		};
	}, [items]);

  return (
    <div className={`carousel-container ${autoplay ? 'autoplay' : ''} speed-${speed}`} onClick={()=> { setAutoplay(false) }}>
	  	{
	  	// render carousel once speed is set
	  	speed && (
	  		<Flickity
	  			flickityRef={c => (flkty = c)}
		    	className={
		    		`${itemExpanded ? 'hidden': ''} content__item
		    	`}
		      elementType={'div'}
		      options={flickityOptions}
		      >
					{
						carouselOrder[0]?.sys?.id === newOrder[0]?.id && imagesLoaded ?

						newOrder?.map((v,i) => {
							return (
								<div className="carousel__item loaded" key={i}>
									{
										!v.hideTitle && (
											<div className="carousel__item__title"><a href={`/${v.client ? 'work' : ''}/${v.slug}`} rel="noreferrer"><span className="main">{v.title}</span></a> <span className="subtitle"><a href={`/${v.client ? 'work' : ''}/${v.slug}`} rel="noreferrer">{v.subTitle || null}</a></span></div>
										)
									}
										<div className="carousel__item__background">
											<a href={`/${v.client ? 'work' : ''}/${v.slug}`} rel="noreferrer">
											{ v.contentType.includes('video') ? (
												<video muted autoPlay loop playsInline src={v.url}></video>
											) : <img src={v.url} style={{ 'objectPosition': window.innerWidth <= 850 && v.crop }} /> }
											</a>
										</div>
								</div>
							)
						}) : null
					}
					</Flickity>
	  	)
	  	}
  	</div>
  )
}

export default Carousel;