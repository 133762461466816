import React, { useEffect, useState } from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Banner from './Banner';
import LazyLoad from 'react-lazyload';

const mapOrder = (array, order, key) => {
  array.sort( function (a, b) {
    var A = a[key], B = b[key];
    
    if (order.indexOf(A) > order.indexOf(B)) {
      return 1;
    } else {
      return -1;
    }
    
  });
  return array;
};

const OurPeople = ({content, peopleItems, currentPage}) => {
	const contentItems = content.description?.json.content;
	const [peopleOrder, setPeopleOrder] = useState([]);
	const newOrder = mapOrder(peopleItems, peopleOrder, 'id');

	useEffect(() => {
		const tempPeopleOrder = []
		contentItems.forEach((t) => {
			if (t.nodeType === 'embedded-entry-block' && t.data.target.sys.type === 'Link') {
				tempPeopleOrder.push(t.data.target.sys.id)
			}
		})
		setPeopleOrder([...peopleOrder, ...tempPeopleOrder])
	}, [contentItems])

	const options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => <h2 className="headline">{children}</h2>,
      [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>
    }
  }

	return (
		<div className="people content__item">
			<Banner text={`Meet ${ currentPage === 'strategy-studio' ? 'Our Leaders ↓' : 'Some of Our People ↓'}`} />
			<div className="people__content">
				{contentItems && documentToReactComponents(contentItems[0])}
				<div className="people__list">
				{ newOrder.map((l,i) => {
					return (
						<div key={i} className="people__list__item">
							<div className="people__list__item__description">
								<div className="description__copy">
									<p className="name">{l.name}</p>
									<p className="role">{l.role}</p>
								</div>
							</div>
							{
								l.url !== '#' ? (
									<a href={l.url} target="_blank" rel="noreferrer">
										<LazyLoad height={500} offset={100}>
											<img src={l.image + '?fm=jpg&q=80'} />
										</LazyLoad>
									</a>
								) : (
									<LazyLoad height={500} offset={100}>
										<img src={l.image + '?fm=jpg&q=80'} />
									</LazyLoad>
								)
							}
						</div>
					)
				}) }
				</div>
			</div>
		</div>
	)
}

export default OurPeople;