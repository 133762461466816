import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom"; 
import axios from 'axios';
import AppContext from './AppContext';

const WorkContext = React.createContext();

const WorkProvider = (props) => {
  const appContext = React.useContext(AppContext);
  const [items, setItems] = useState([]);
  const { slug } = useParams();
  const [workLoaded, setWorkLoaded] = useState(false);
  const { pageId } = props;
  const currentWorkItem = items.length > 0 ? items.find(w => w.slug === slug) : null;
  const currentNum = items.indexOf(currentWorkItem);
  const accessToken = appContext.cDelivery;
  const endpoint = `https://graphql.contentful.com/content/v1/spaces/${process.env.REACT_APP_SPACE}/environments/${appContext.env}`;

  const getItems = () => {
    const WORK_ITEMS = `query {
      page(id: "${pageId}") {
        contentCollection {
          items {
            ...on ContentItem {
              title
              subTitle
              slug
              client
              description {
                json
              }
            }
          }
        }
      }
    }`;

    axios({
      url: endpoint,
      method: "GET",
      headers: {
        "Authorization": `Bearer ${appContext.cDelivery}`,
        "Content-Type": "application/json"
      },
      params: {
        query: WORK_ITEMS
      }
    })
    .then(({data}) => {
    	let itemsProcessed = 0;

    	return data.data.page.contentCollection.items.forEach((v,i) => {
    		if(v.slug){
    			itemsProcessed++;
    			setItems(items => [...items,  v])

    			if(itemsProcessed === data.data.page.contentCollection.items.length - 1) {
	    			setWorkLoaded(true);
	    		}
    		}
    	})
    })
  }

  useEffect(() => {
    return getItems();
  }, [])

  return (
    <WorkContext.Provider value={{items: items, currentNum: currentNum, workLoaded: workLoaded}}>
  		{props.children}
  	</WorkContext.Provider>
  )
}

const WorkConsumer = WorkContext.Consumer;
export { WorkContext, WorkProvider, WorkConsumer }