import React from 'react';
import { useEffect } from 'react';
import AppContext from '../contexts/AppContext';
import Footer from '../components/Footer';

const PrivacyPolicy = () => {
	const appContext = React.useContext(AppContext);

	useEffect(() => {
		appContext.setOrangeNav(true);
	}, [ appContext ]);

  return (
    <div className="page terms-privacy">
				<h1>Privacy Policy</h1>
<p>72 and Sunny Partners LLC (“We”) respects your privacy. This privacy policy (“the Privacy
Policy”) describes the types of information We collect about individuals (“You”) on
www.72andsunny.com (the “Website”), how We use the information and your rights in
connection.</p>
<h2>1. How We collect your information</h2>
<p>1.1 The information We collect about You depends on how You interact with us or use our
Website. Your information may also be included in content You submit to us through the email
addresses on the Website. This information may include: your contact information, such as your
name, mailing address, email address, phone number; employment information, education
information and any other information You choose to provide to us.</p>
<p>1.2 In addition, We may automatically collect certain information about the computer or
devices (including mobile devices or tablets) You use to access the Website. We may collect and
analyze information such as (a) IP addresses, location information, unique device identifiers,
IMEI and TCP/IP address, and other information about your computer or device(s), browser
types, browser language, operating system, mobile device carrier information, the state or
country from which You accessed the Website; and (b) information related to the ways in which
You interact with the Website, such as: referring and exit web pages and URLs, platform type,
the number of clicks, domain names, landing pages, pages and content viewed and the order of
those pages, statistical information about the use of the Website, the amount of time spent on
particular pages, the date and time You used the Website, the frequency of your use of the
Website, error logs, and other similar information.</p>
<p>1.3 We may also collect data about your use of our Website through the use of Internet server
logs (where we store Website activity) and cookies and/or tracking pixels as defined below.</p>
<ul>
<li><strong>Cookies:</strong> A cookie is a small piece of code that is sent from a website You browse and
stored in your web browser. Cookies relay certain information to us or to third parties
that enables us to: (a) recognize your computer; (b) store your preferences and settings;
(c) understand the web pages You have visited; (d) enhance your user experience by
delivering content and advertisements specific to your inferred interests; (e) perform
searches and analytics; and (f) assist with security administrative functions. Cookies can
be essential to use and navigate the Website, functional (i.e. to remember your choices
and preferences or to show You relevant content) or for analytics purposes (to
understand the way You use the Website).</li>
<li><strong>Pixels or Web Beacons:</strong> Pixels or web beacons may also communicate information from
your Internet browser to a web server. Pixels can be embedded in web pages, videos, or
emails, and can allow a web server to read certain types of information from your
browser, check whether You have viewed a particular web page or email message, and
determine, among other things, the time and date on which You viewed the Web
beacon, the IP address of Your computer, and the URL of the web page from which the
Web Beacon was viewed. If you elect to communicate with us through the email
addresses provided on the Website, we may include Web Beacons in email messages
and other electronic communications to determine whether the message has been
opened and for other analytics, personalisation or advertising purposes. As We adopt
additional technologies, we may also gather additional information through other
methods.</li>
</ul>
<p>The information collected and stored through these technologies may include IP address,
unique identifiers, time stamps, browser type and other browser information, referring
websites, and more generally, what web pages You visit and how You interact with them, and
what ads You view and how You interact with them.</p>
<p>We and our partners, including Google Analytics, may also use these technologies for analyzing
usage trends, assisting with fraud prevention, and providing certain features to You. To prevent
Google Analytics from using Your information for analytics, You may install the Google Analytics
Opt-out Browser Add-on by clicking <a href="https://tools.google.com/dlpage/gaoptout">here</a>.</p>
<p>We may be provided information relating to visitors to the Website by third-parties that use
tracking technology to identify such visitors based on their IP addresses. Partners include in
particular: LinkedIn, Google Analytics, Facebook, Medium and the privacy practices of these
third parties are subject to the privacy statements of such third parties which are available on
their website.</p>
<p>You can change your settings to notify You when a Cookie is being set or updated, or to block
Cookies altogether. Please consult the “Help” section of Your browser for more information
(e.g., Internet Explorer; Google Chrome; Mozilla Firefox; or Apple Safari). You can also manage
the use of Flash technologies, including Cookies and local storage objects with the Flash
management tools available at Adobe&rsquo;s Website.</p>
<p>Please note that by blocking any or all Cookies, You may not have access to certain features or
offerings of the Website or certain features or offerings of the Website may not function
properly.</p>
<h2>2. How We use the information We collect</h2>
<p>We may use the data we collect as follows:</p>
<ul>
<li>For the purposes for which You provided it;</li>
<li>To recognize and authenticate You on the Website;</li>
<li>To initiate or to provide the features available through the Website or to provide
our services;</li>
<li>To send You information about your relationship or transactions with us or other
communications, such as newsletters, if any, to which You have subscribed;</li>
<li>To process and respond to your inquiries or to request your feedback;</li>
<li>For internal research and reporting;</li>
<li>To improve the content and features of the Website or our services or develop
new services;</li>
<li>To enforce the legal terms that govern your use of the Website or our services;</li>
<li>To administer and troubleshoot the Website or our services; and</li>
<li>To send you communications required by law or which are necessary to inform
you about changes to the services we provide and this Privacy Policy. (These
service messages will not include any promotional content and do not require
prior consent when sent by email).</li>
</ul>
<p>Please note that We may combine information that We collect from You and about You
(including automatically-collected information) with information We obtain about You from our
affiliates and/or non-affiliated third parties, and use such combined information in accordance
with this Privacy Policy.</p>
<p>We may use de-identified and/or aggregated data for any purpose, including without limitation
for research and marketing purposes, and may also share such data with any third parties,
including advertisers, promotional partners, and/or others.</p>
<h2>3. How We Disclose Your Information</h2>

<p>We may disclose Your information as follows:</p>

<ul>
  <li>To our parents, affiliates, subsidiaries, and other companies under common control and ownership.</li>
  <li>To third parties who perform services on our behalf, including without limitation marketing, market research, customer support, data storage and data analytics, analysis and processing, and legal services.</li>
  <li>To manage our relationships, correspondence and record-keeping in respect of clients and prospective clients.</li>
  <li>Where required to do so by law or in a good faith belief that such access, preservation, or disclosure is permitted by this Privacy Policy or reasonably necessary or appropriate for any of the following reasons:
    <ul>
      <li>(a) to comply with legal process i.e. if We are required to respond to subpoenas, court orders or are asked to respond to legitimate requests for Your personal information from law enforcement or government agencies. In such situations, we will release Your information to these organizations as we reasonably believe appropriate for us to comply with the law and to prevent against fraud. We will not provide You with these requests for information made by law enforcement or government agencies;</li>
      <li>(b) to enforce this Privacy Policy or other contracts with You, including investigation of potential violations thereof;</li>
      <li>(c) to respond to claims that any content violates the rights of third parties;</li>
      <li>(d) to respond to Your requests for customer service; and/or</li>
      <li>(e) to protect our rights, property, or personal safety and those of our agents and affiliates, its users, and the public. This includes exchanging information with other companies and organizations for fraud protection, and spam/malware prevention, and similar purposes.</li>
    </ul>
  </li>
  <li>As part of business transactions, (including in contemplation of such transactions, e.g., due diligence), such as buying, merging with, or partnering with other businesses or as part of an asset sale.</li>
</ul>

<h2>4. Security and Data Retention</h2>

<p>We have implemented reasonable administrative, technical, and physical security measures designed to protect against the loss, misuse, and/or alteration of your information. While We strive to protect your information, We cannot and do not guarantee that our efforts will prevent every unauthorized attempt to access, use, or disclose your information because no Internet and/or other electronic transmissions can be completely secure.</p>

<p>Whenever We collect your personal data, We&rsquo;ll only keep it for as long as is necessary for the purpose for which it was collected unless a longer retention period is required or permitted by applicable law. At the end of that retention period, We will either delete your data or anonymize it, for example by aggregation with other data so that it can be used in a non-identifiable way for statistical analysis and business planning purposes.</p>

<h2>5. Data Transfers</h2>

<p>We may transfer the information We collect about You to recipients (including our subsidiaries and affiliates, and third party service providers) in countries other than the country in which the information may have originally been collected. Those countries may not have the same data protections regulations as the country in which You originally provided the information. We will protect the information We collect as described in this Policy and will comply with applicable legal requirements.. By providing us with Your information, You understand that Your personal information will be processed in or outside the United States or the European Economic Area (EEA).</p>
<h2>6. Your Choices and Rights</h2>

<p>We offer You certain choices in connection with the information We collect and in particular to update or correct your information or submit a request, You can contact us as described below in the How to contact us section.</p>

<p>If You shared your email address with us and receive our electronic communications, You can unsubscribe from such communications by clicking the “unsubscribe” link they contain. You may opt out of the use of Cookies as set forth in Section 1 above.</p>

<h2>7. Additional Information for California Residents</h2>

<p>Upon receipt of a verifiable request by You, and as required by applicable law, we will provide a response to such requests.</p>

<ul>
  <li><strong>Right to know about information collected or disclosed.</strong> In addition to what is described above, as a California resident, You also have the right to request more information regarding the following topics, to the extent applicable: the categories of personal information, the categories of sources from which the personal information is collected, the business or commercial purpose for collecting, selling, or sharing personal information, if applicable, the categories of third parties to whom We disclose personal information, and the specific pieces of personal information We collected about You.</li>
  <li><strong>Right to request deletion of your personal information.</strong> You have the right to request that We delete the personal information We collect or maintain about You. Once we receive your request, We will let you know what, if any, personal information We can delete from our records, and We will notify any service providers and contractors with whom We disclosed Your personal information to also delete your personal information from their records. There may be circumstances where We cannot delete your personal information or notify service providers or contractors to delete your personal information from their records. Such instances include, but are not limited to, enabling solely internal uses that are reasonably aligned with your expectations based on your relationship with us and compatible with the context in which You provided the information or to comply with a legal obligation.</li>
  <li><strong>Right to request correction.</strong> You have the right to request that We correct any inaccurate personal information We maintain about You, taking into account the nature of that information and purpose for processing it.</li>
  <li><strong>Right to non-discrimination for the exercise of your privacy rights.</strong> We will not discriminate or retaliate against You for exercising any of your rights as described above. We do not sell as that term is defined under applicable law, the above categories of personal information.</li>
  <li><strong>Submitting consumer rights requests.</strong> To submit a California Consumer Rights request as outlined above, You may contact as defined in the How to contact us section below.</li>
</ul>

<h2>8. Additional Information for EU Residents</h2>

<p>The EU General Data Protection Regulation (or the “GDPR”) requires the disclosure of certain information about the processing of “Personal Data.” “Personal Data” is a term used in Europe that means, generally, data that identifies or can identify a particular unique user or device – for instance, names, addresses, Cookie identifiers, mobile device identifiers, precise location data and biometric data.</p>

<p>To comply with the GDPR, We provide the below information, which are specific to persons located in EEA (the European Economic Area) countries or Switzerland (so please don&rsquo;t rely on the below, if You are not located in those countries):</p>

<ul>
  <li><strong>Legal grounds for processing your information:</strong> when We act as a “data controller”, the GDPR requires us to tell You about the legal basis we&rsquo;re relying on to process any personal data about You. The legal basis for us processing your information for the purposes set out in Section 1 “Information We Collect” and Section 2 “How We use the information We collect” above and will typically be because (a) We have legitimate interests (i.e. when We use your information to maintain the security of our services, and when we communicate with or otherwise manage our client information; (b) You provided your consent; (c) in the context of a contractual relationship we have (such as our client records and contact information); (d) to comply with our legal or regulatory obligations.</li>
  <li><strong>Data retention:</strong> as a general matter, we retain your information for as long as necessary to provide our Website and services, or for other important purposes such as complying with legal obligations, resolving disputes, and enforcing our agreements, as well as for auditing, corporate record-keeping, compliance accounting or security and bug-prevention purposes.</li>
  <li><strong>Your rights:</strong> The GDPR provides You with certain rights to access the information We collect, to request correction of the your information when incorrect, out of date or incomplete, to withdraw your consent where We process your information pursuant to your consent, to request to restrict or delete your information, and to object to our processing of your information (including profiling for online ad targeting if any). If applicable, you can request that We don&rsquo;t use your information for direct marketing activity. To exercise those rights, You may contact us as described in the How to contact us section below. You also have the right to lodge a complaint with your local supervisory authority and for residents of the EEA, UK and Switzerland with the Information Commissioner&rsquo;s Office (by calling them at 0303 123 1113 or online at www.ico.org.uk/concerns). However, We hope that You will first consult with us, so that We may work with You to resolve any complaint or concern You might have. To protect the confidentiality of your information, We will ask You to verify your identity before proceeding with any request You make under this Privacy Policy.</li></ul>

<h2>9. Links to Other Sites</h2>

<p>Our Website may contain links to other websites (e.g. Instagram, Linkedin, Medium). Our Privacy Policy does not extend to those third-party Websites and We are not responsible for these parties&rsquo; policies in particular in connection with their data protection practices. To have further information on such policies, please consult said third party&rsquo;s website.</p>

<h2>10. Children&rsquo;s Privacy</h2>

<p>Our Website and services are intended for general audiences and not for children under the age of 13. If We become aware that We have collected personal information (as defined by the Children&rsquo;s Online Privacy Protection Act) from children under the age of 13, We will take reasonable steps to delete it as soon as practical. If You believe that a child under the age of 13 has provided us with personal information, please contact us as specified in the How to contact us section below.</p>

<h2>11. How to Contact Us</h2>

<p>If You have any questions regarding our privacy practices, You may contact us by email at <a href="mailto:privacy@72andSunny.com">privacy@72andSunny.com</a> and <a href="mailto:legal@72andsunny.com">legal@72andsunny.com</a>; by mail at 72andSunny Partners LLC, 12101 Bluff Creek Dr, Playa Vista, CA 90094, attn: Legal Department.</p>

<h2>12. Change of Policy</h2>

<p>We reserve the right to make changes to this Privacy Policy at any time; in which case, We will post such changes on the Website. Your continued use of the Site following the posting of any changes to this Privacy Policy means you accept such changes.</p>

<h2>13. Terms and Conditions of Use</h2>

<p>Your use of the Website is governed by and subject to the <a href="/terms-and-conditions">Terms and Conditions of Use</a> posted on the Website. This Privacy Policy is incorporated into such Terms and Conditions of Use.</p>

<p style={{marginTop: '20px', fontSize: '14px', fontStyle: 'italic'}}>This Privacy Policy was last updated on April 2nd 2024</p>
				<Footer />
		</div>
  )
}

export default PrivacyPolicy;