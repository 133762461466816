import React from 'react';
import LoopingVideo from './LoopingVideo';

const TwoColumnModule = (props) => {
  const captions = [props.content.caption1,props.content.caption2];

  return (
  	<div className={`content-image module-2-column expanded__media`}>
      <div className="media__items">
      {
        props.content.media[0].mediaCollection.items.map((m, i) => {
          if(m.url.includes('video')) {
            return <LoopingVideo key={i} vidUrl={m.url} caption={captions[i]} />
          } else {
            return (
              <div className="module-2-column__image" key={i}>
                <img key={i} src={m.url + '?fm=jpg&q=80'} />
                {
                  captions[i] ? (
                    <p key={`caption${i}`} className="caption">{captions[i]}</p>
                  ) : null
                }
              </div>
            )
          }
        })
      }
      </div>
  	</div>
  )
}

export default TwoColumnModule;